import React from 'react';
import PropTypes from 'prop-types';
import { CFormInput, CFormLabel } from '@coreui/react';

const InputField = (props) => {
    const { field, form, type, label, disabled, isRequired, attributes = {} } = props;
    const placeholder = props.placeholder ?? label;
    const { name } = field;
    const { errors, touched } = form;
    let showError = errors[name] && touched[name];
    if (errors[name] && props.title) {
        errors[name] = errors[name].replace(name, props.title);
    }
    field.value = field.value === undefined ? '' : field.value;

    return (
        <>
            <div className="mb-3">
                <CFormLabel htmlFor={name}>
                    {label} {isRequired ? <span style={{ color: 'red' }}>*</span> : null}
                </CFormLabel>
                <CFormInput
                    id={name}
                    {...field}
                    type={type}
                    disabled={disabled}
                    placeholder={placeholder}
                    {...attributes}
                />
                {showError && <p className={'text-danger'}>{errors[name]}</p>}
            </div>
        </>
    );
};

InputField.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
};

InputField.defaultProps = {
    type: 'text',
    label: '',
    disabled: false,
    isRequired: false,
};

export default InputField;
