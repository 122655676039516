
const EditButton = ({ onClick, label = ' Sửa' }) => {
    return (
        <div>
            <button type="button" onClick={onClick} className="btn m-1 btn-sm bg-warning">
                {label}
            </button>
        </div>
    );
};

export default EditButton;
