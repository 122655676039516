import { CButton, CCard, CCardBody, CFormInput } from '@coreui/react';

import Service from 'src/services/video.service';

import { useRef, useState } from 'react';
import DeleteButton from 'src/components/DataTable/DeleteButton';
import DSelect from 'src/components/Form/DSelect';
import GridResource from 'src/components/GridResource';
import HasRole from 'src/components/auth/HasRole';
import { DOWNLOAD_STATUSES, DOWNLOAD_STATUS_OPTIONS, ROLES } from 'src/constants';
import { get_form_data } from 'src/helpers';
import { show_date } from 'src/helpers/date';
import useAdmins from 'src/hooks/useAdmins';
import useRoles from 'src/hooks/useRoles';
import notify from 'src/utils/notify';

const Video = () => {
    const childRef = useRef();
    const [admins] = useAdmins();
    const [, isRole] = useRoles();
    const [isStarting, setIsStarting] = useState(false);
    const [isStopping, setIsStopping] = useState(false);

    const formSettings = [
        { label: 'Mô tả', name: 'title', validate: 'string|nullable' },
        { label: 'Video Link', name: 'video_link', validate: 'string|required' },
    ];

    const gridSettings = [
        { label: 'Id', column: 'id' },
        { label: 'Tiêu đề', column: 'title' },
        {
            label: 'status',
            column: 'status',
            render: ({ item }) => {
                return DOWNLOAD_STATUSES[item.status] ?? 'Pending';
            },
        },
        {
            label: 'Thumbnail',
            column: 'admin',
            render: ({ item }) => {
                return item.image_url ? <img src={item.image_url} alt={item.title} style={{ maxHeight: 80 }} /> : '';
            },
        },

        { label: 'admin', column: 'admin', render: ({ item }) => item.admin },

        { label: 'Ngày Tạo', column: 'created_at', render: ({ item }) => show_date(item.created_at) },

        {
            label: 'Hành Động',
            render: ({ item, extraData }) => {
                return (
                    <div className="d-flex justify-content-center">
                        {item.video_url ? (
                            <a target="_blank" href={item.video_url} className="btn m-1 btn-sm btn-success">
                                Xem video
                            </a>
                        ) : (
                            ''
                        )}
                        <DeleteButton onClick={() => childRef.current.onDelete(item)} />
                    </div>
                );
            },
        },
    ];

    function handleStartLive(live) {
        if (isStarting) {
            notify.warning('Đang tiến hành start live');
            return;
        }
        setIsStarting(true);
        Service.startLive(live.id)
            .then((data) => {
                if (data.status === 'error') {
                    notify.error(data.message);
                    return;
                }
                notify.success('Live thành công');
                childRef.current.reload();
            })
            .finally(() => {
                setIsStarting(false);
            });
    }

    function onSubmitForm(e) {
        e.preventDefault();
        const data = get_form_data(e);

        childRef.current.setQueryParams((pre) => ({ ...pre, ...data, page: 1 }));
    }

    async function onSubmit(data, actions) {
        childRef.current.onSubmit(data, actions);
    }

    return (
        <>
            <CCard className="mb-4">
                <CCardBody className="pb-0">
                    <form className="row mb-4" onSubmit={onSubmitForm}>
                        <HasRole roles={[ROLES.ROLE_LEADER]}>
                            <div className="col-3">
                                <label htmlFor="">Thành viên</label>
                                <DSelect name="admin_id" options={admins} />
                            </div>
                        </HasRole>
                        <div className="col-3">
                            <label htmlFor="">Ngày tạo</label>
                            <CFormInput type="date" name="date"></CFormInput>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Live status</label>
                            <DSelect
                                name="status"
                                options={[{ label: 'Status', value: '' }, ...DOWNLOAD_STATUS_OPTIONS]}
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <CButton type="submit" className="btn-sm">
                                Tìm
                            </CButton>
                        </div>
                    </form>
                </CCardBody>
            </CCard>

            <GridResource
                ref={childRef}
                moduleName="liên kết"
                gridSettings={gridSettings}
                formSettings={formSettings}
                service={Service}
                customSubmit={onSubmit}
                extraData={(isStarting, isStopping)}
            />
        </>
    );
};
export default Video;
