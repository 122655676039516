import { useRef } from 'react';
import EditButton from 'src/components/DataTable/EditButton';
import LabelRole from 'src/components/DataTable/LabelRole';
import LabelStatus from 'src/components/DataTable/LabelStatus';
import SelectField from 'src/components/Form/SelectField';
import GridResource from 'src/components/GridResource';
import HasRole from 'src/components/auth/HasRole';
import { ACTIVE_OPTIONS, ROLES, ROLE_OPTIONS } from 'src/constants';
import AdminService from 'src/services/admin.service';
import AdminFilter from './AdminFilter';
import useLeaders from 'src/hooks/useLeaders';
import { formatDate } from 'src/utils/date';
import useRoles from 'src/hooks/useRoles';
import { CLink } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { loginById } from 'src/store/user';
import DeleteButton from 'src/components/DataTable/DeleteButton';

const Admin = () => {
    const childRef = useRef();
    const [checkRole] = useRoles();
    const [leaders] = useLeaders();
    function onSubmit(data) {
        childRef.current.setQueryParams((pre) => ({ ...pre, ...data, page: 1 }));
    }

    const dispatch = useDispatch();

    function handleLoginById(id) {
        dispatch(loginById(id));
    }
    const formSettings = [
        { label: 'Tên', name: 'name', validate: 'string|required' },
        {
            label: 'Email',
            name: 'email',
            attributes: {
                autoComplete: 'off',
            },

            onUpdate: {
                attributes: {
                    disabled: true,
                },
            },
            onCreate: {
                attributes: {
                    disabled: false,
                },
                validate: 'string|email|required',
            },
        },
        {
            label: 'Password',
            name: 'password',
            attributes: {
                type: 'password',
                autoComplete: 'off',
            },
            onCreate: {
                validate: 'string|required|min:1|max:20',
            },
            onUpdate: {
                validate: 'string|optional|min:1|max:20',
            },
        },
        {
            label: 'Trạng thái',
            name: 'active',
            component: SelectField,
            attributes: {
                options: ACTIVE_OPTIONS,
            },
            default: 1,
        },
        { label: 'IP đăng nhập', name: 'allow_ips', roles: [ROLES.ROLE_SUPER] },

        {
            label: 'Quyền',
            name: 'role',
            component: SelectField,
            attributes: {
                options: ROLE_OPTIONS,
            },
            roles: [ROLES.ROLE_SUPER],
        },
        {
            label: 'Leader',
            name: 'leader_id',
            component: SelectField,
            attributes: {
                options: leaders,
            },
            roles: [ROLES.ROLE_SUPER],
            render: ({ formikProps, i }) => {
                if (+formikProps.values.role !== ROLES.ROLE_MEMBER) return null;
                return i;
                // return true;
            },
        },
    ];

    const gridSettings = [
        {
            label: 'Id',
            column: 'id',
            render: ({ item }) => {
                if (checkRole(ROLES.ROLE_SUPER)) {
                    return (
                        <CLink
                            className="clink"
                            onClick={() => handleLoginById(item.id)}
                            style={{
                                textDecoration: 'undeline',
                            }}
                        >
                            {item.id}
                        </CLink>
                    );
                }
                return item.id;
            },
        },
        { label: 'Name', column: 'name' },
        { label: 'Email', column: 'email' },
        {
            label: 'Status',
            column: 'status',
            render: ({ item }) => {
                return <LabelStatus status={item.active} />;
            },
            roles: [ROLES.ROLE_LEADER],
        },
        {
            label: 'Role',
            render: ({ item }) => {
                return <LabelRole role={item.role} />;
            },
        },
        { label: 'Leader', column: 'leader_name' },
        {
            label: 'Ngày tạo',
            render: ({ item }) => {
                return formatDate(item.created_at);
            },
        },
        {
            label: 'Act',
            render: ({ item }) => {
                return (
                    <div className="d-flex justify-content-center">
                        <EditButton onClick={() => childRef.current.onEdit(item)} />
                        {!item.active && checkRole(ROLES.ROLE_SUPER) ? (
                            <DeleteButton onClick={() => childRef.current.onDelete(item)} label="Delete" />
                        ) : (
                            ''
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <HasRole roles={[ROLES.ROLE_SUPER, ROLES.ROLE_LEADER]}>
                <AdminFilter leaders={leaders} onSubmit={onSubmit} />
            </HasRole>
            <GridResource
                ref={childRef}
                moduleName="thành viên"
                gridSettings={gridSettings}
                formSettings={formSettings}
                service={AdminService}
                params={{
                    page: 1,
                    active: 1,
                    page_size: 20,
                }}
            />
        </>
    );
};

export default Admin;
