import { CButton, CCard, CCardBody, CFormInput } from '@coreui/react';

import LiveStreamService from 'src/services/livestream.service';

import { useRef, useState } from 'react';
import DeleteButton from 'src/components/DataTable/DeleteButton';
import DSelect from 'src/components/Form/DSelect';
import SelectField from 'src/components/Form/SelectField';
import GridResource from 'src/components/GridResource';
import HasRole from 'src/components/auth/HasRole';
import { DOWNLOAD_STATUSES, DOWNLOAD_STATUS_DOWNLOADED, ROLES, STREAM_URLS } from 'src/constants';
import { show_date } from 'src/helpers/date';
import useAdmins from 'src/hooks/useAdmins';
import useRoles from 'src/hooks/useRoles';
import notify from 'src/utils/notify';
import { get } from 'lodash';
import { get_form_data } from 'src/helpers';

const LiveStream = () => {
    const childRef = useRef();
    const [admins] = useAdmins();
    const [, isRole] = useRoles();
    const [isStarting, setIsStarting] = useState(false);
    const [isStopping, setIsStopping] = useState(false);

    const formSettings = [
        { label: 'Mô tả', name: 'page_name', validate: 'string|nullable' },
        {
            label: 'Stream URL',
            name: 'stream_url',
            component: SelectField,
            attributes: {
                options: STREAM_URLS,
            },
        },
        { label: 'Stream Key', name: 'stream_key', validate: 'string|required' },
        { label: 'Video ids', name: 'videos' },
    ];

    const gridSettings = [
        { label: 'Id', column: 'id' },
        { label: 'Tên Page', column: 'page_name' },
        { label: 'Stream URL', column: 'stream_url' },
        { label: 'Stream Key', column: 'stream_key' },
        {
            label: 'Live status',
            column: 'is_live',
            render: ({ item }) => {
                return (
                    <div className="ring-container">
                        <div className="ringring"></div>
                        <div className={`circle ${item.is_live ? 'online' : 'offline'}`}></div>
                    </div>
                );
            },
        },

        {
            label: 'Video',
            column: 'download_status',
            render: ({ item }) => {
                return DOWNLOAD_STATUSES[item.download_status] || 'Pending';
            },
        },
        { label: 'admin', column: 'admin', render: ({ item }) => item.admin },

        { label: 'Ngày Tạo', column: 'created_at', render: ({ item }) => show_date(item.created_at) },

        {
            label: 'Hành Động',
            render: ({ item, extraData }) => {
                return (
                    <div className="d-flex justify-content-center">
                        {item.is_live ? (
                            <CButton
                                className="btn m-1 btn-sm btn-danger"
                                disabled={extraData.isStopping}
                                onClick={() => handleStopLive(item)}
                            >
                                Stop live
                            </CButton>
                        ) : (
                            <>
                                {item.download_status === DOWNLOAD_STATUS_DOWNLOADED ? (
                                    <CButton
                                        className="btn m-1 btn-sm btn-success"
                                        onClick={() => handleStartLive(item)}
                                        disabled={extraData.isStarting}
                                    >
                                        Start live
                                    </CButton>
                                ) : (
                                    <CButton
                                        className="btn m-1 btn-sm btn-success"
                                        onClick={() => handleDownload(item)}
                                    >
                                        Download
                                    </CButton>
                                )}
                                <DeleteButton onClick={() => childRef.current.onDelete(item)} />
                            </>
                        )}
                    </div>
                );
            },
        },
    ];

    function handleStartLive(live) {
        if (isStarting) {
            notify.warning('Đang tiến hành start live');
            return;
        }
        setIsStarting(true);
        LiveStreamService.startLive(live.id)
            .then((data) => {
                if (data.status === 'error') {
                    notify.error(data.message);
                    return;
                }
                notify.success('Live thành công');
                childRef.current.reload();
            })
            .finally(() => {
                setIsStarting(false);
            });
    }
    function handleDownload(live) {
        LiveStreamService.download(live.id).then((data) => {
            if (data.status === 'error') {
                notify.error(data.message);
                return;
            }
            notify.success('Đang tiến hành download');
            childRef.current.reload();
        });
    }

    function handleStopLive(live) {
        if (isStopping) {
            notify.warning('Từ từ thôi');
            return;
        }
        setIsStopping(true);
        LiveStreamService.stopLive(live.id)
            .then((res) => {
                console.log(res);

                notify.success('Stop thành công');
                childRef.current.reload();
            })
            .finally(() => {
                setIsStopping(false);
            });
    }
    function onSubmitForm(e) {
        e.preventDefault();
        const formSearch = get_form_data(e);
        childRef.current.setQueryParams((pre) => ({ ...pre, ...formSearch, page: 1 }));
    }

    function validateIsUrl(inputValue) {
        const urlRegEx = /^(rtmp|https?):\/\/[a-z0-9-\.]+\.[a-z]{2,}(:\d+)?\/?([^\s\/?#]+\/?)*$/i;
        if (urlRegEx.test(inputValue)) {
            return true;
        } else {
            return false;
        }
    }
    async function onSubmit(data, actions) {
        console.log(data);
        // const validate = validateIsUrl(data.stream_url);
        // if (validate) {
        //     childRef.current.onSubmit(data, actions);
        // } else {
        //     notify.warning('Vui lòng nhập Stream URL');
        // }
    }

    function gridHooks(event, data) {
        console.log(event, data);
    }

    return (
        <>
            <CCard className="mb-4">
                <CCardBody className="pb-0">
                    <form className="row mb-4" onSubmit={onSubmitForm}>
                        <HasRole roles={[ROLES.ROLE_LEADER]}>
                            <div className="col-3">
                                <label htmlFor="">Thành viên</label>
                                <DSelect name="admin_id" options={admins} />
                            </div>
                        </HasRole>
                        <div className="col-3">
                            <label htmlFor="">Ngày tạo</label>
                            <CFormInput type="date" name="date"></CFormInput>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Live status</label>
                            <DSelect
                                name="is_live"
                                options={[
                                    { label: 'Live status', value: '' },
                                    { label: 'Đang live', value: 1 },
                                    { label: 'Không live', value: 0 },
                                ]}
                            />
                        </div>
                        <div className="col-12 mt-2">
                            <CButton type="submit" className="btn-sm">
                                Tìm
                            </CButton>
                        </div>
                    </form>
                </CCardBody>
            </CCard>

            <GridResource
                ref={childRef}
                moduleName="liên kết"
                gridSettings={gridSettings}
                formSettings={formSettings}
                service={LiveStreamService}
                customSubmit={onSubmit}
                extraData={(isStarting, isStopping)}
                hook={gridHooks}
            />
        </>
    );
};
export default LiveStream;
