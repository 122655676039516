import GridTable from 'src/components/GridTable';
import { ROLES } from 'src/constants';
import { number_format } from 'src/utils/number';

const StatisticDetailCountryByAdmin = ({ data }) => {
    const list = data;
    const setting = [
        { label: 'Quốc Gia', column: 'country_code' },
        {
            label: 'Tổng Lượt Xem',
            column: 'views',
            render: ({ item }) => {
                return number_format(item.views);
            },
        },
        {
            label: 'RPM',
            column: 'rpm',
            roles: [ROLES.ROLE_LEADER],
        },
        {
            label: 'Tổng Tiền ($)',
            column: 'money',
            render: ({ item }) => {
                return (item.money * 1).toFixed(4);
            },
            roles: [ROLES.ROLE_LEADER],
        },
    ];
    return <GridTable setting={setting} data={{ list }} pagination={false} />;
};

export default StatisticDetailCountryByAdmin;
