import { CSpinner } from '@coreui/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Pagination from 'src/components/Pagination';
import useRoles from 'src/hooks/useRoles';

GridTable.propTypes = {
    onPageChange: PropTypes.func,
    paging: PropTypes.object,
    items: PropTypes.array,
    children: PropTypes.func,
    pagination: PropTypes.bool,
    isLoading: PropTypes.bool,
    showLoading: PropTypes.bool,
    setting: PropTypes.array.isRequired,
};
GridTable.defaultProps = {
    pagination: true,
    paging: {
        page: 1,
        total: 0,
        page_size: 20,
    },
    items: [],
    onPageChange: () => {},
    isLoading: false,
    showLoading: false,
};

function GridTable(props) {
    const [checkRole] = useRoles();

    const { data, onPageChange, pagination, isLoading, showLoading, setting, extraData = null } = props;
    const {
        paging = {
            page: 1,
            page_size: 20,
            total: 0,
        },
        list: items = [],
    } = data;
    const [settingRender] = useState(() => {
        const settings = [];
        setting.forEach((i) => {
            if (i.roles && !checkRole(i.roles)) return;

            let settingItem = {};
            if (typeof i === 'string') {
                settingItem.label = i;
                settingItem.render = ({ item }) => item.i;
            } else {
                settingItem = {
                    ...i,
                    label: i.label ? i.label : i.column,
                    render: i.render ? i.render : ({ item }) => item[i.column],
                };
            }

            settings.push(settingItem);
        });
        return settings;
    });

    const totalPages = paging.total ? Math.ceil(paging.total / paging.page_size) : 1;

    const total = paging ? paging.total : items.length;

    return (
        <>
            <div className="base-table">
                <table className="table custom-datatable table-sticky">
                    <thead>
                        <tr>
                            {settingRender.map((i, index) => {
                                return <td key={index}>{i.label}</td>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {showLoading && isLoading ? (
                            <>
                                <tr>
                                    <td colSpan={settingRender.length} className="text-center">
                                        <CSpinner component="span" aria-hidden="true" />
                                    </td>
                                </tr>
                            </>
                        ) : (
                            items.map((item, index) => {
                                return (
                                    <tr className="text-center" key={index}>
                                        {settingRender.map((i, settingIndex) => {
                                            return (
                                                <td key={settingIndex} className={i.class ?? ''}>
                                                    {i.render({ item, extraData, index: index })}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>
            {pagination ? (
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <div>
                        {paging.total > 0 ? (
                            <Pagination
                                size="sm"
                                activePage={paging.page * 1}
                                pages={totalPages}
                                onActivePageChange={onPageChange}
                            />
                        ) : null}
                    </div>
                    Found {total} records
                </div>
            ) : null}
        </>
    );
}

export default GridTable;
