import { useState } from 'react';
import SelectorLeader from 'src/components/Admin/SelectorLeader';
import { isUrl } from 'src/helpers/validate';
import ShortLinkService from 'src/services/shortlink.service';
import notify from 'src/utils/notify';

const FormUpdateLink = ({ onSuccess = () => {} }) => {
    const [teams, setTeams] = useState([]);

    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const link = formData.get('link');
        if (!isUrl(link)) {
            notify.warning('Vui lòng nhập đúng link ');
            return;
        }
        if (!teams.length) {
            notify.warning('Vui lòng chọn team');
            return;
        }
        if (window.confirm(`Bạn có chắc không cập nhật toàn bộ link của ${teams.length} team không`)) {
            ShortLinkService.updateLinks({
                link,
                teams,
            })
                .then((res) => {
                    notify.success();
                    onSuccess(res);
                })
                .catch(notify.response);
        }
    }
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                    <label htmlFor="">Link mới</label>
                    <input type="text" className="form-control" name="link" />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="">Team áp dụng</label>
                        <div>Số team đã chọn: {teams.length}</div>
                    </div>
                    <SelectorLeader onDataChange={setTeams} />
                </div>
                <div className="text-center">
                    <button className="btn btn-warning px-5" type="submit">
                        Update
                    </button>
                </div>
            </form>
        </div>
    );
};

export default FormUpdateLink;
