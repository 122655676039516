import { CCardBody, CCardText } from '@coreui/react';
import { useEffect, useState } from 'react';
import GridTable from 'src/components/GridTable';
import HasRole from 'src/components/auth/HasRole';
import { ROLES } from 'src/constants';
import { format_money } from 'src/helpers';
import StatisticService from 'src/services/statistic.service';
import notify from 'src/utils/notify';
import { number_format } from 'src/utils/number';

const StatisticByCountry = ({ queryParams }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        list: [],
        total: {
            total_views: 0,
            total_money: 0,
        },
    });
    useEffect(() => {
        fetchData();
    }, [queryParams]);

    const setting = [
        {
            label: 'Top',
            render: ({ index }) => {
                return index + 1;
            },
        },
        {
            label: 'Quốc Gia',
            render: ({ item }) => {
                return (
                    <>
                        {item.country_name} <img className="mx-2" height={15} src={item.country_img} alt="" />
                    </>
                );
            },
        },
        {
            label: 'Tổng Lượt Xem',
            column: 'total_views',
            render: ({ item }) => {
                return number_format(item.total_views);
            },
        },
        {
            label: 'Tổng Tiền',
            column: 'total_money',
            render: ({ item }) => {
                return number_format(item.total_money);
            },
            roles: [ROLES.ROLE_LEADER],
        },
        {
            label: 'RPM',
            column: 'rpm',
            roles: [ROLES.ROLE_LEADER],
        },
    ];

    function fetchData() {
        setIsLoading(true);
        StatisticService.byCountry(queryParams)
            .then((data) => {
                setData(data);
            })
            .catch(notify.response)
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            <CCardBody>
                <CCardText>Tổng Lượt Xem {number_format(data.total.total_views)}</CCardText>
                <HasRole roles={[ROLES.ROLE_LEADER]}>
                    <CCardText>Tổng Tiền {format_money(data.total.total_money)}</CCardText>
                </HasRole>
            </CCardBody>
            <GridTable
                setting={setting}
                data={data}
                pagination={false}
                extraData={queryParams}
                isLoading={isLoading}
                showLoading={true}
            />
        </>
    );
};

export default StatisticByCountry;
