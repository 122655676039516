import {
    CButton,
    CCard,
    CCardBody,
    CFormInput,
    CFormSelect,
    CLink,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';
import { useState } from 'react';

import ShortLinkService from 'src/services/shortlink.service';

import { useRef } from 'react';
import DeleteButton from 'src/components/DataTable/DeleteButton';
import EditButton from 'src/components/DataTable/EditButton';
import DSelect from 'src/components/Form/DSelect';
import ImageField from 'src/components/Form/ImageField';
import Textarea from 'src/components/Form/Textarea';
import GridResource from 'src/components/GridResource';
import HasRole from 'src/components/auth/HasRole';
import { ROLES } from 'src/constants';
import { str_random } from 'src/helpers';
import { show_date } from 'src/helpers/date';
import useAdmins from 'src/hooks/useAdmins';
import useRoles from 'src/hooks/useRoles';
import notify from 'src/utils/notify';
import FormUpdateLink from './FormUpdateLink';

const ShortLink = () => {
    const childRef = useRef();
    const [admins] = useAdmins();
    const [, isRole] = useRoles();
    const [isUpdateLink, setIsUpdateLink] = useState(false);

    const formSettings = [
        !isRole(ROLES.ROLE_SUPER)
            ? {
                  label: 'Đích Đến',
                  name: 'link',
                  validate: 'string|url|required',
                  onUpdate: {
                      attributes: {
                          disabled: true,
                      },
                  },
                  onCreate: {
                      attributes: {
                          disabled: false,
                      },
                  },
              }
            : {
                  label: 'Đích Đến',
                  name: 'link',
                  validate: 'string|url|required',
              },

        {
            label: 'Code',
            name: 'code',
            attributes: {
                autoComplete: 'false',
                disabled: true,
            },
        },
        { label: 'Tiêu Đề', name: 'meta_title', validate: 'string|required' },
        { label: 'Ghi Chú', name: 'name', validate: 'string' },
        { label: 'Mô Tả', name: 'meta_description', component: Textarea, col: 12 },
        { label: 'Điền Link Ảnh Cần Fake Hoặc Tải Lên', name: 'meta_image', component: ImageField },
        { label: 'Link IMGUR', name: 'meta_image1', validate: 'string' },
    ];

    const [domainSelected, setDomainSelected] = useState({});

    function changeDomain(event, item) {
        let value = event.target.value.trim();

        setDomainSelected((pre) => {
            return {
                ...pre,
                [item.id]: value,
            };
        });
    }

    const extraData = {
        domainSelected,
    };

    const gridSettings = [
        { label: 'Id', column: 'id' },

        {
            label: 'Liên Kết',
            column: 'link',
            render: ({ item, extraData }) => {
                const domain = extraData?.domainSelected[item.id] ?? '';
                const url = `http://${domain}/${str_random(7)}/${str_random(7)}${item.code}`;
                const tiktok_url = `http://${domain}/${str_random(7)}/${str_random(7)}${item.code}.html`;
                const tiktok_url2 = `http://${domain}/${str_random(7)}/${str_random(7)}${item.code}.htm`;
                const image_url = `http://${domain}/${str_random(6)}/${str_random(6)}${item.code}.jpg`;
                const vercel_url = `http://${domain}/${str_random(5)}/${str_random(5)}${item.code}`;
                const bitly_url = `http://${domain}/v/${item.code}`;
                const v3_url = `http://${domain}/${str_random(6)}/${str_random(6)}${item.code}.html`;
                const image_url2 = `http://${domain}/${str_random(8)}/${str_random(8)}${item.code}.jpeg`;
                let options = item.domain_referer.map((i, index) => {
                    return {
                        label: i.name,
                        value: i.name,
                    };
                });
                options.unshift({
                    label: 'Chọn Domain',
                    value: '',
                });
                const handleCopyLinks = (link) => {
                    navigator.clipboard
                        .writeText(link)
                        .then(() => {
                            notify.success('Đã Copy Liên Kết');
                        })
                        .catch(() => {
                            notify.error('Copy Thất Bại');
                        });
                };
                return (
                    <>
                        <CFormSelect
                            onChange={(event) => changeDomain(event, item)}
                            style={{ width: 200, margin: 'auto' }}
                            options={options}
                        />
                        {domain ? (
                            <>
                                <div>
                                    Short Link :{' '}
                                    <CLink
                                        target="_blank"
                                        href={url}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleCopyLinks(url);
                                        }}
                                    >
                                        {url}
                                    </CLink>
                                </div>

                                <div>
                                    Magic Link :{' '}
                                    <CLink
                                        target="_blank"
                                        href={image_url}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleCopyLinks(image_url);
                                        }}
                                    >
                                        {image_url}
                                    </CLink>
                                </div>
                                <div>
                                    Magic Link V2 :{' '}
                                    <CLink
                                        target="_blank"
                                        href={image_url2}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleCopyLinks(image_url2);
                                        }}
                                    >
                                        {image_url2}
                                    </CLink>
                                </div>
                                <div>
                                    Smart Link :{' '}
                                    <CLink
                                        target="_blank"
                                        href={tiktok_url}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleCopyLinks(tiktok_url);
                                        }}
                                    >
                                        {tiktok_url}
                                    </CLink>
                                </div>
                                
                                <div>
                                    2 Trạm V2:{' '}
                                    <CLink
                                        target="_blank"
                                        href={bitly_url}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleCopyLinks(bitly_url);
                                        }}
                                    >
                                        {bitly_url}
                                    </CLink>
                                </div>
                                <div>
                                    2 Trạm V3:{' '}
                                    <CLink
                                        target="_blank"
                                        href={v3_url}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleCopyLinks(v3_url);
                                        }}
                                    >
                                        {v3_url}
                                    </CLink>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </>
                );
            },
        },
        { label: 'Đích Đến', column: 'link' },
        { label: 'Lượt Xem', column: 'views' },
        { label: 'Người Tạo', column: 'admin_name' },
        {
            label: 'Thời Gian Tạo',
            column: 'created_at',
            render: ({ item }) => {
                return show_date(item.created_at);
            },
        },
        { label: 'Ghi Chú', column: 'name' },
        {
            label: 'Hành Động',
            render: ({ item, extraData }) => {
                const domain = extraData?.domainSelected[item.id] ?? '';
                const image_url = domain ? `http://${domain}/${str_random(6)}/${str_random(6)}${item.code}.jpg` : '';

                const handleCopyClick = () => {
                    const link = document.createElement('a');
                    link.href = image_url;
                    //link.innerText = item.name || 'Smart Traffic';
                    link.innerHTML = `<strong>${item.name || 'Smart Traffic'}</strong>`;
                    document.body.appendChild(link);

                    const range = document.createRange();
                    range.selectNode(link);
                    window.getSelection().removeAllRanges();
                    window.getSelection().addRange(range);

                    document.execCommand('copy');
                    document.body.removeChild(link);
                    notify.success('Magic Copy Thành Công, Dán Vào Group Để Tận Hưởng');
                };

                return (
                    <div className="d-flex justify-content-center">
                        <button className="btn m-1 btn-sm bg-success" onClick={handleCopyClick}>
                            Magic
                        </button>
                        <EditButton onClick={() => childRef.current.onEdit(item)} />
                        <DeleteButton onClick={() => childRef.current.onDelete(item)} />
                    </div>
                );
            },
        },
    ];

    function onSubmitForm(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const admin_id = formData.get('admin_id');
        const search = formData.get('search');
        const date = formData.get('date');
        childRef.current.setQueryParams((pre) => ({ ...pre, admin_id, search, date, page: 1 }));
    }

    const customActions = {
        updateLink: isRole([ROLES.ROLE_SUPER, ROLES.ROLE_LEADER]) ? (
            <button type="button" className="btn m-1 btn-sm bg-primary" onClick={() => setIsUpdateLink(true)}>
                Sửa đích
            </button>
        ) : undefined,
    };
    function handleUpdateLinkSuccess() {
        setIsUpdateLink(false);
        childRef.current.reload();
    }

    return (
        <>
            <CCard className="mb-4">
                <CCardBody className="pb-0">
                    <form className="row mb-4" onSubmit={onSubmitForm}>
                        <HasRole roles={[ROLES.ROLE_LEADER]}>
                            <div className="col-3">
                                <label htmlFor="">Thành viên</label>
                                <DSelect name="admin_id" options={admins} />
                            </div>
                        </HasRole>
                        <div className="col-3">
                            <label htmlFor="">Tìm Theo Code</label>
                            <CFormInput name="search"></CFormInput>
                        </div>
                        <div className="col-3">
                            <label htmlFor="">Ngày tạo</label>
                            <CFormInput type="date" name="date"></CFormInput>
                        </div>
                        <div className="col-12 mt-2">
                            <CButton type="submit" className="btn-sm">
                                Tìm
                            </CButton>
                        </div>
                    </form>
                </CCardBody>
            </CCard>

            <CModal visible={isUpdateLink} backdrop={'static'} size="lg" onClose={() => setIsUpdateLink(false)}>
                <CModalHeader>
                    <CModalTitle>Sửa đích toàn bộ liên kết của team</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <FormUpdateLink onSuccess={handleUpdateLinkSuccess} />
                </CModalBody>
            </CModal>
            <GridResource
                ref={childRef}
                moduleName="liên kết"
                gridSettings={gridSettings}
                formSettings={formSettings}
                service={ShortLinkService}
                extraData={extraData}
                customActions={customActions}
                actions={['updateLink', 'create', 'reload']}
            />
        </>
    );
};
export default ShortLink;
