import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AdminLayout from 'src/components/layouts/AdminLayout';
import AuthLayout from 'src/components/layouts/AuthLayout';
import { APP_PATH, AUTH_PATH, DEFAULT_PATH } from 'src/constants/app.const';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

import { ROLES } from 'src/constants';
import useRoles from 'src/hooks/useRoles';
import Admin from 'src/pages/admin/admin/Admin';
import Country from 'src/pages/admin/country/Country';
import Dashboard from 'src/pages/admin/dashboard/Dashboard';
import Domain from 'src/pages/admin/domain/Domain';
import SettingConfig from 'src/pages/admin/setting/SettingConfig';
import ShortLink from 'src/pages/admin/shortlink/ShortLink';
import Statistic from 'src/pages/admin/statistic/Statistic';
import Wallet from 'src/pages/admin/wallet/Wallet';
import LiveStream from 'src/pages/admin/livestream/Livestream';
import Video from 'src/pages/admin/video/Video';

const Login = React.lazy(() => import('src/pages/auth/Login'));

export const adminRoutes = [
    {
        path: 'dashboard',
        element: <Dashboard />,
        name: 'Trang Chủ',
    },
    {
        path: 'statistic',
        element: <Statistic />,
        name: 'Thống Kê',
        // roles: [ROLES.ROLE_SUPER, ROLES.ROLE_LEADER],
    },
    {
        path: 'admin',
        element: <Admin />,
        name: 'Thành Viên',
        roles: [ROLES.ROLE_SUPER, ROLES.ROLE_LEADER],
    },
    {
        path: 'short_link',
        element: <ShortLink />,
        name: 'Quản Lý Liên Kết',
    },
    {
        path: 'domain',
        element: <Domain />,
        name: 'Danh Sách Domain',
        //roles: [ROLES.ROLE_SUPER],
    },
    {
        path: 'wallet',
        element: <Wallet />,
        name: 'Ví',
        roles: [ROLES.ROLE_SUPER],
    },
    {
        path: 'country',
        element: <Country />,
        name: 'RPM Quốc Gia',
        roles: [ROLES.ROLE_SUPER],
    },
    {
        path: 'video',
        element: <Video />,
        name: 'Video',
        roles: [ROLES.ROLE_SUPER],
    },
    {
        path: 'livestream',
        element: <LiveStream />,
        name: 'Livestream',
        roles: [ROLES.ROLE_SUPER],
    },
    {
        path: 'setting/config',
        element: <SettingConfig />,
        name: 'Cấu Hình Chung',
        roles: [ROLES.ROLE_SUPER],
    },
    {
        path: '*',
        element: <Navigate to={DEFAULT_PATH} replace />,
        menu: {
            isHiden: true,
        },
    },
];

const AppRouter = () => {
    const [checkRole] = useRoles();

    const routes = adminRoutes.filter((route) => {
        return route.roles ? checkRole(route.roles) : true;
    });
    return useRoutes([
        {
            path: APP_PATH + '/*',
            element: (
                <PrivateRoute>
                    <AdminLayout />
                </PrivateRoute>
            ),
            children: routes,
        },
        {
            path: AUTH_PATH + '/*',
            element: (
                <PublicRoute>
                    <AuthLayout />
                </PublicRoute>
            ),
            children: [
                {
                    path: 'login',
                    element: <Login />,
                },
            ],
        },
        {
            path: '*',
            element: <Navigate to={DEFAULT_PATH} replace />,
        },
    ]);
};

export default AppRouter;
