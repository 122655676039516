
const DeleteButton = ({ onClick,label='Xóa' }) => {
    return (
        <div>
            <button type="button" onClick={onClick} className="btn m-1 btn-sm bg-danger">
                {label}
            </button>
        </div>
    );
};

export default DeleteButton;
