import { CButton, CCard, CCardBody, CFormInput } from '@coreui/react';
import DSelect from 'src/components/Form/DSelect';
import HasRole from 'src/components/auth/HasRole';
import { ACTIVE_OPTIONS, ROLES, ROLE_OPTIONS } from 'src/constants';

function AdminFilter({ leaders = [], onSubmit }) {
    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const leader_id = formData.get('leader_id')?.trim();
        const search = formData.get('search')?.trim();
        const active = formData.get('active')?.trim();
        const role = formData.get('role')?.trim();
        onSubmit({
            leader_id,
            search,
            active,
            role,
        });
    }

    return (
        <CCard className="mb-4">
            <CCardBody className="pb-0">
                <form className="row mb-4" onSubmit={handleSubmit}>
                    <HasRole roles={ROLES.ROLE_SUPER}>
                        <div className="col-3">
                            <label htmlFor="">Leader</label>
                            <DSelect name="leader_id" options={leaders}></DSelect>
                        </div>

                        <div className="col-3">
                            <label htmlFor="">Role</label>
                            <DSelect
                                name="role"
                                options={ROLE_OPTIONS}
                                placeholder="Chọn role"
                                defaultSelected={''}
                            ></DSelect>
                        </div>
                    </HasRole>
                    <div className="col-3">
                        <label htmlFor="">Status</label>
                        <DSelect
                            name="active"
                            options={ACTIVE_OPTIONS}
                            placeholder="Chọn status"
                            defaultSelected={1}
                        ></DSelect>
                    </div>
                    <div className="col-3">
                        <label htmlFor="">Tìm </label>
                        <CFormInput name="search"></CFormInput>
                    </div>
                    <div className="col-12 mt-2">
                        <CButton type="submit" className="btn-sm">
                            Search
                        </CButton>
                    </div>
                </form>
            </CCardBody>
        </CCard>
    );
}

AdminFilter.propTypes = {};

export default AdminFilter;
