import { CButton, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';
import { useState } from 'react';
import GridTable from 'src/components/GridTable';
import { number_format } from 'src/utils/number';
import StatisticDetailCountryByAdmin from './StatisticDetailCountryByAdmin';
import { ROLES } from 'src/constants';

const StatisticDetailAdmin = ({ data }) => {
    const list = data;
    const [dataDetail, setDataDetail] = useState({
        showModal: false,
        list: [],
    });
    const setting = [
        { label: 'Ngày', column: 'date' },
        {
            label: 'Tổng Lượt Xem',
            column: 'views',
            render: ({ item }) => {
                return number_format(item.views);
            },
        },
        {
            label: 'Tổng Tiền',
            column: 'money',
            render: ({ item }) => {
                return number_format((item.money * 1).toFixed(2)) + ' $';
            },
            roles: [ROLES.ROLE_LEADER],
        },
        {
            label: 'Chi Tiết Hơn',
            render: ({ item }) => {
                return (
                    <CButton className="btn-sm" onClick={() => showDetailCountry(item.detail)}>
                        Xem
                    </CButton>
                );
            },
        },
    ];
    function showDetailCountry(detail) {
        const sorted = detail.sort((a, b) => {
            return b.views - a.views;
        });
        setDataDetail({
            showModal: true,
            list: sorted,
        });
    }
    return (
        <>
            <CModal
                visible={dataDetail.showModal}
                size="lg"
                onClose={() => setDataDetail((pre) => ({ ...pre, showModal: false }))}
            >
                <CModalHeader>
                    <CModalTitle>Chi Tiết Theo Quốc Gia </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <StatisticDetailCountryByAdmin data={dataDetail.list} />
                </CModalBody>
            </CModal>
            <GridTable setting={setting} data={{ list }} pagination={false} />
        </>
    );
};

export default StatisticDetailAdmin;
