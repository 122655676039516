import { useEffect } from 'react';
import { useState } from 'react';
import useLeaders from 'src/hooks/useLeaders';

const SelectorLeader = ({ onDataChange = () => {} }) => {
    const [leaders] = useLeaders(false);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        onDataChange(Object.keys(selected).map(i=>+i));
    }, [selected]);

    function handleSelectAll() {
        const newSelected =
            Object.keys(selected).length === leaders.length
                ? {}
                : leaders.reduce((pre, i) => {
                      pre[i.value] = 1;
                      return pre;
                  }, {});
        setSelected(newSelected);
    }

    function handleSelectItem(key) {
        setSelected((pre) => {
            if (pre[key]) {
                delete pre[key];
            } else {
                pre[key] = 1;
            }
            return { ...pre };
        });
    }
    return (
        <div>
            <div>
                <div className="form-group">
                    <div className="select-admins">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Team</th>
                                    <th className="text-center">
                                        <div className="form-check">
                                            <label className="form-check-label">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={Object.keys(selected).length === leaders.length}
                                                    onChange={() => handleSelectAll()}
                                                />
                                                Chọn tất cả
                                            </label>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {leaders.map((i) => {
                                    const itemSelected = !!selected[i.value];
                                    return (
                                        <tr key={i.value}>
                                            <td>{i.value}</td>
                                            <td>{i.label}</td>
                                            <td className="text-center">
                                                <div className="form-check">
                                                    <label className="form-check-label">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={itemSelected}
                                                            onChange={() => handleSelectItem(i.value)}
                                                        />
                                                        Chọn
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectorLeader;
